import './src/styles/global.css'
import "@fontsource/montserrat"
import "@fontsource/montserrat/variable.css"
import '@fontsource-variable/montserrat';
import "@fontsource/baskervville"

export const onInitialClientRender = () => {
    window.gtag_report_conversion = function (url) {
        var callback = function () {
            if (typeof url !== "undefined") {
                window.location = url;
            }
        };
        window.gtag("event", "conversion", {
            send_to: "AW-11479802445/uJcqCOeD-LQZEM3E_-Eq",
            event_callback: callback,
        });
        return false;
    };
};
